  import React, { useState, useEffect } from "react";
  import axios from "axios";
  import "../../asset/css/RestaurantListPage.css";

  const RestaurantListPage = () => {
    const [foodItems, setFoodItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [editingFood, setEditingFood] = useState(null);
    const [editedFood, setEditedFood] = useState({});

    useEffect(() => {
      const fetchFoodData = async () => {
        const loggedInPartnerId = localStorage.getItem("restaurant_partner_id");
        try {
          const response = await axios.get("https://hungreli.com/api/getallfoodlist.php", {
            params: { restaurant_partner_id: loggedInPartnerId },
          });

          if (response.data.status === "success") {
            setFoodItems(response.data.data || []);
          } else {
            console.error("Failed to fetch food data:", response.data.message);
          }
        } catch (err) {
          console.error("Error fetching food data:", err);
        } finally {
          setLoading(false);
        }
      };

      fetchFoodData();
    }, []);

    const handleEditClick = (food) => {
      setEditingFood(food.id);
      setEditedFood(food);
    };

    const handleChange = (e) => {
      setEditedFood({ ...editedFood, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e) => {
      setEditedFood({ ...editedFood, image: e.target.files[0] });
    };

    const handleUpdate = async () => {
      try {
        const formData = new FormData();
        
        // Ensure that inStock is being sent as a number (boolean)
        formData.append("inStock", editedFood.inStock === "1" ? "1" : "0");
    
        // Append other food properties
        Object.keys(editedFood).forEach((key) => {
          if (key !== 'image') { // Prevent overwriting the image with an empty value
            formData.append(key, editedFood[key]);
          }
        });
    
        // Append the new image if it exists
        if (editedFood.image) {
          formData.append("image", editedFood.image);
        }
    
        const response = await axios.post("https://hungreli.com/api/updatefood.php", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
    
        if (response.data.status === "success") {
          setFoodItems((prevItems) =>
            prevItems.map((item) => (item.id === editedFood.id ? editedFood : item))
          );
          setEditingFood(null);
        } else {
          console.error("Failed to update food item:", response.data.message);
        }
      } catch (err) {
        console.error("Error updating food item:", err);
      }
    };
    

    if (loading) {
      return <p>Loading food items...</p>;
    }

    return (
      <div className="container">
        <h1>Your Registered Food Items</h1>
        {foodItems.length === 0 ? (
          <p>No food items found. Please register some items.</p>
        ) : (
          <ul className="food-list">
            {foodItems.map((food) => (
              <li key={food.id} className="food-item">
                {editingFood === food.id ? (
                  <div className="edit-form">
                    <input type="text" name="foodName" value={editedFood.foodName} onChange={handleChange} />
                    <input type="text" name="category" value={editedFood.category} onChange={handleChange} />
                    <input type="number" name="price" value={editedFood.price} onChange={handleChange} />
                    <input type="number" name="discountPrice" value={editedFood.discountPrice} onChange={handleChange} />
                    <textarea name="description" value={editedFood.description} onChange={handleChange} />
                    <select name="inStock" value={editedFood.inStock} onChange={handleChange}>
                      <option value="1">In Stock</option>
                      <option value="0">Out of Stock</option>
                    </select>
                    <input type="file" name="image" onChange={handleImageChange} />
                    <button onClick={handleUpdate} className="save-btn">Save</button>
                    <button onClick={() => setEditingFood(null)} className="cancel-btn">Cancel</button>
                  </div>
                ) : (
                  <>
                    <h3>{food.foodName}</h3>
                    <p>Category: {food.category}</p>
                    <p>Price: ₹{food.price}</p>
                    <p>Discount Price: ₹{food.discountPrice}</p>
                    <p className={food.inStock === "1" ? "in-stock" : "out-of-stock"}>
                      {food.inStock === "1" ? "In Stock" : "Out of Stock"}
                    </p>
                    <p>Description: {food.description}</p>
                    <button onClick={() => handleEditClick(food)} className="edit-btn">Edit</button>
                  </>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  export default RestaurantListPage;
