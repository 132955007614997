// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Button, Table, Alert, Spinner } from "react-bootstrap";
// import "../../asset/css/Admindashboard.css";

// const AdminDashboard = () => {
//   const [restaurantPartners, setRestaurantPartners] = useState([]);
//   const [deliveryBoys, setDeliveryBoys] = useState([]);
//   const [message, setMessage] = useState("");
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   // Fetch data for restaurant partners and delivery boys
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);
//         const [restaurantsResponse, deliveryBoysResponse] = await Promise.all([
//           axios.get("https://hungreli.com/api/restaurant_partner.php"),
//           axios.get("https://hungreli.com/api/delivery_boy.php"),
//         ]);

//         if (restaurantsResponse.data.status === "success") {
//           setRestaurantPartners(restaurantsResponse.data.data);
//         } else {
//           setMessage(restaurantsResponse.data.message || "Failed to load restaurants.");
//         }

//         if (deliveryBoysResponse.data.status === "success") {
//           setDeliveryBoys(deliveryBoysResponse.data.data);
//         } else {
//           setMessage(deliveryBoysResponse.data.message || "Failed to load delivery boys.");
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//         setMessage("Failed to load data. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//   }, []);

//   // Update status helpers
//   // const updateStatus = async (endpoint, id, status, setState) => {
//   //   try {
//   //     const response = await axios.patch(endpoint, { id, status });
//   //     setMessage(response.data.message);
//   //     if (response.data.status === "success") {
//   //       setState((prev) =>
//   //         prev.map((item) => (item.id === id ? { ...item, status } : item))
//   //       );
//   //     }
//   //   } catch (error) {
//   //     console.error(`Error updating status for ID ${id}:`, error);
//   //     setMessage("Failed to update status.");
//   //   }
//   // };

//   const updateStatus = async (endpoint, id, status, setState, stateData) => {
//     // Optimistically update the UI
//     const updatedData = stateData.map((item) =>
//       item.id === id ? { ...item, statusrestaurant: status } : item
//     );
//     setState(updatedData);
  
//     try {
//       const response = await axios.patch(endpoint, { id, status });
//       setMessage(response.data.message);
  
//       if (response.data.status !== "success") {
//         // Revert the status on failure
//         const revertedData = stateData.map((item) =>
//           item.id === id ? { ...item, statusrestaurant: "1" } : item
//         );
//         setState(revertedData);
//       }
//     } catch (error) {
//       console.error(`Error updating status for ID ${id}:`, error);
//       setMessage("Failed to update status.");
//       // Revert the status on error
//       const revertedData = stateData.map((item) =>
//         item.id === id ? { ...item, statusrestaurant: "1" } : item
//       );
//       setState(revertedData);
//     }
//   };
  

//   const handleViewProfile = () => {
//     navigate("/adminProfile");
//   };

//   return (
//     <div className="admin-dashboard">
//       {/* Header Section */}
//       <div className="dashboard-header">
//         <h1 className="dashboard-heading">Admin Dashboard</h1>
//         <div className="profile-dropdown">
//           <button className="view-profile-btn" onClick={handleViewProfile}>
//             View Profile
//           </button>
//         </div>
//       </div>

//       {/* Status Messages */}
//       {message && <Alert variant="info" className="mt-3">{message}</Alert>}

//       {loading ? (
//         <div className="text-center mt-4">
//           <Spinner animation="border" variant="primary" />
//           <p>Loading data...</p>
//         </div>
//       ) : (
//         <>
//           {/* Restaurant Partners Table */}
//           <h2>Restaurant Partners</h2>
//           <Table striped bordered hover responsive>
//             <thead>
//               <tr>
//                 <th>Restaurant unique ID</th>
//                 <th>Restaurant Name</th>
//                 <th>Owner Name</th>
//                 <th>Email</th>
//                 <th>Contact Number</th>
//                 <th>Address</th>
//                 <th>Cuisine Type</th>
//                 <th>GST Number</th>
//                 <th>Food License</th>
//                 <th>Declaration Accepted</th>
//                 <th>Status</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {restaurantPartners.map((partner) => (
//                 <tr key={partner.id}>
//                   <td>{partner.restaurant_partner_id}</td>
//                   <td>{partner.restaurantName}</td>
//                   <td>{partner.ownerName}</td>
//                   <td>{partner.email}</td>
//                   <td>{partner.contactNumber}</td>
//                   <td>{partner.address}</td>
//                   <td>{partner.cuisineType}</td>
//                   <td>{partner.gstNumber}</td>
//                   <td>{partner.foodLicense}</td>
//                   <td>{partner.declarationAccepted ? "Yes" : "No"}</td>
//                   <td>{partner.statusrestaurant === "1" ? "Pending" : partner.statusrestaurant === "2" ? "Active" : "Pending"}</td>
//                   <td>
//                   <Button
//                       variant="success"
//                       onClick={() =>
//                         updateStatus(
//                           "https://hungreli.com/api/updateRestaurantStatus.php",
//                           partner.restaurant_partner_id,
//                           "Active",
//                           setRestaurantPartners,
//                           restaurantPartners // Pass the current state data
//                         )
//                       }
//                       disabled={partner.statusrestaurant === "2"}
//                     >
//                       Accept
//                     </Button>

//                     <Button
//                       variant="danger"
//                       onClick={() =>
//                         updateStatus(
//                           "https://hungreli.com/api/updateRestaurantStatus.php",
//                           partner.restaurant_partner_id,
//                           "Blocked",
//                           setRestaurantPartners,
//                           restaurantPartners
//                         )
//                       }
//                       disabled={partner.statusrestaurant === "1"}
//                     >
//                       Block
//                     </Button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>

//           {/* Delivery Boys Table */}
//           <h2>Delivery Boys</h2>
//           <Table striped bordered hover responsive>
//             <thead>
//               <tr>
//                 <th>ID</th>
//                 <th>Name</th>
//                 <th>Username</th>
//                 <th>Email</th>
//                 <th>Emergency Contact</th>
//                 <th>Govt Address Proof</th>
//                 <th>ID Proof</th>
//                 <th>Address</th>
//                 <th>Status</th>
//                 <th>Action</th>
//               </tr>
//             </thead>
//             <tbody>
//               {deliveryBoys.map((boy) => (
//                 <tr key={boy.id}>
//                   <td>{boy.id}</td>
//                   <td>{boy.name}</td>
//                   <td>{boy.username}</td>
//                   <td>{boy.email}</td>
//                   <td>{boy.contact_emergency}</td>
//                   <td>{boy.govt_address_proof}</td>
//                   <td>{boy.id_proof}</td>
//                   <td>{boy.address}</td>
//                   <td>{boy.status || "Pending"}</td>
//                   <td>
//                     <Button
//                       variant="success"
//                       onClick={() =>
//                         updateStatus(
//                           "https://hungreli.com/api/updateDeliveryBoyStatus.php",
//                           boy.id,
//                           "Active",
//                           setDeliveryBoys
//                         )
//                       }
//                       disabled={boy.status === "Active"}
//                     >
//                       Active
//                     </Button>
//                     <Button
//                       variant="danger"
//                       onClick={() =>
//                         updateStatus(
//                           "https://hungreli.com/api/updateDeliveryBoyStatus.php",
//                           boy.id,
//                           "Blocked",
//                           setDeliveryBoys
//                         )
//                       }
//                       disabled={boy.status === "Blocked"}
//                     >
//                       Block
//                     </Button>
//                     <Button
//                       variant="warning"
//                       onClick={() =>
//                         updateStatus(
//                           "https://hungreli.com/api/updateDeliveryBoyStatus.php",
//                           boy.id,
//                           "Pending",
//                           setDeliveryBoys
//                         )
//                       }
//                       disabled={boy.status === "Pending"}
//                     >
//                       Pending
//                     </Button>
//                     <Button
//                       variant="info"
//                       onClick={() =>
//                         updateStatus(
//                           "https://hungreli.com/api/updateDeliveryBoyStatus.php",
//                           boy.id,
//                           "Inactive",
//                           setDeliveryBoys
//                         )
//                       }
//                       disabled={boy.status === "Inactive"}
//                     >
//                       Inactive
//                     </Button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </>
//       )}

//       {/* About Section */}
//       <div className="about-section mt-4">
//         <p>
//           Our platform is dedicated to connecting customers with the best local
//           restaurants and ensuring timely delivery with the help of our skilled
//           delivery personnel. We aim to create a seamless dining experience from
//           order to delivery.
//         </p>
//       </div>
//     </div>
//   );
// };

// export default AdminDashboard;

// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { Button, Table, Alert, Spinner, Tabs, Tab } from "react-bootstrap";
// import "../../asset/css/Admindashboard.css";

// const AdminDashboard = () => {
//   const [restaurantPartners, setRestaurantPartners] = useState([]);
//   const [deliveryBoys, setDeliveryBoys] = useState([]);
//   const [message, setMessage] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [key, setKey] = useState("manage");
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         setLoading(true);
//         const [restaurantsResponse, deliveryBoysResponse] = await Promise.all([
//           axios.get("https://hungreli.com/api/restaurant_partner.php"),
//           axios.get("https://hungreli.com/api/delivery_boy.php"),
//         ]);

//         if (restaurantsResponse.data.status === "success") {
//           setRestaurantPartners(restaurantsResponse.data.data);
//         }
//         if (deliveryBoysResponse.data.status === "success") {
//           setDeliveryBoys(deliveryBoysResponse.data.data);
//         }
//       } catch (error) {
//         setMessage("Failed to load data. Please try again later.");
//       } finally {
//         setLoading(false);
//       }
//     };
//     fetchData();
//   }, []);

//   const deleteItem = async (endpoint, id, setState) => {
//     try {
//       await axios.delete(`${endpoint}?id=${id}`);
//       setState((prev) => prev.filter((item) => item.id !== id));
//     } catch (error) {
//       setMessage("Failed to delete item.");
//     }
//   };

//   return (
//     <div className="admin-dashboard">
//       <h1 className="dashboard-heading">Admin Dashboard</h1>
//       <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
//         <Tab eventKey="manage" title="Manage Restaurants & Delivery">
//           {message && <Alert variant="info">{message}</Alert>}
//           {loading ? (
//             <div className="text-center">
//               <Spinner animation="border" />
//               <p>Loading...</p>
//             </div>
//           ) : (
//             <>
//               <h2>Manage Restaurants</h2>
//               <Table striped bordered hover>
//                 <thead>
//                   <tr>
//                     <th>ID</th>
//                     <th>Name</th>
//                     <th>Owner</th>
//                     <th>Email</th>
//                     <th>Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {restaurantPartners.map((partner) => (
//                     <tr key={partner.id}>
//                       <td>{partner.id}</td>
//                       <td>{partner.restaurantName}</td>
//                       <td>{partner.ownerName}</td>
//                       <td>{partner.email}</td>
//                       <td>
//                         <Button variant="primary" onClick={() => navigate(`/edit-restaurant/${partner.id}`)}>Edit</Button>
//                         <Button variant="danger" onClick={() => deleteItem("https://hungreli.com/api/deleteRestaurant.php", partner.id, setRestaurantPartners)}>Delete</Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>

//               <h2>Manage Delivery Boys</h2>
//               <Table striped bordered hover>
//                 <thead>
//                   <tr>
//                     <th>ID</th>
//                     <th>Name</th>
//                     <th>Username</th>
//                     <th>Email</th>
//                     <th>Action</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {deliveryBoys.map((boy) => (
//                     <tr key={boy.id}>
//                       <td>{boy.id}</td>
//                       <td>{boy.name}</td>
//                       <td>{boy.username}</td>
//                       <td>{boy.email}</td>
//                       <td>
//                         <Button variant="primary" onClick={() => navigate(`/edit-delivery/${boy.id}`)}>Edit</Button>
//                         <Button variant="danger" onClick={() => deleteItem("https://hungreli.com/api/deleteDeliveryBoy.php", boy.id, setDeliveryBoys)}>Delete</Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//             </>
//           )}
//         </Tab>
//       </Tabs>
//     </div>
//   );
// };

// export default AdminDashboard;

import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Button, Table, Alert, Spinner, Tabs, Tab } from "react-bootstrap";
import "../../asset/css/Admindashboard.css";

const AdminDashboard = () => {
  const [restaurantPartners, setRestaurantPartners] = useState([]);
  const [deliveryBoys, setDeliveryBoys] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("dashboard");
  // const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [restaurantsResponse, deliveryBoysResponse] = await Promise.all([
          axios.get("https://hungreli.com/api/restaurant_partner.php"),
          axios.get("https://hungreli.com/api/delivery_boy.php"),
        ]);

        if (restaurantsResponse.data.status === "success") {
          setRestaurantPartners(restaurantsResponse.data.data);
        }
        if (deliveryBoysResponse.data.status === "success") {
          setDeliveryBoys(deliveryBoysResponse.data.data);
        }
      } catch (error) {
        setMessage("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const deleteRestaurant = async (id) => {
    try {
      await axios.post("https://hungreli.com/api/delete_restaurant.php", { id });
      setRestaurantPartners(restaurantPartners.filter((partner) => partner.id !== id));
    } catch (error) {
      alert("Failed to delete restaurant. Please try again.");
    }
  };

  const deleteDeliveryBoy = async (id) => {
    try {
      await axios.post("https://hungreli.com/api/delete_delivery_boy.php", { id });
      setDeliveryBoys(deliveryBoys.filter((boy) => boy.id !== id));
    } catch (error) {
      alert("Failed to delete delivery boy. Please try again.");
    }
  };

  return (
    <div className="admin-dashboard">
      <h1 className="dashboard-heading">Admin Dashboard</h1>
      <Tabs activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
        <Tab eventKey="dashboard" title="Dashboard">
          {message && <Alert variant="info">{message}</Alert>}
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" />
              <p>Loading...</p>
            </div>
          ) : (
            <>
              <h2>Restaurant Partners</h2>
              <div className="table-container">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Owner</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {restaurantPartners.map((partner) => (
                      <tr key={partner.id}>
                        <td>{partner.id}</td>
                        <td>{partner.restaurantName}</td>
                        <td>{partner.ownerName}</td>
                        <td>{partner.email}</td>
                        <td>{partner.statusrestaurant}</td>
                        <td>
                          <Button variant="success">Active</Button>
                          <Button variant="danger">Block</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              
              <h2>Delivery Boys</h2>
              <div className="table-container">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {deliveryBoys.map((boy) => (
                      <tr key={boy.id}>
                        <td>{boy.id}</td>
                        <td>{boy.name}</td>
                        <td>{boy.email}</td>
                        <td>{boy.status}</td>
                        <td>
                          <Button variant="success">Active</Button>
                          <Button variant="danger">Block</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Tab>
        <Tab eventKey="manage" title="Manage Restaurants & Delivery">
          <h2>Manage Restaurants</h2>
          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Owner</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {restaurantPartners.map((partner) => (
                  <tr key={partner.id}>
                    <td>{partner.id}</td>
                    <td>{partner.restaurantName}</td>
                    <td>{partner.ownerName}</td>
                    <td>{partner.email}</td>
                    <td>
                      <Button variant="danger" onClick={() => deleteRestaurant(partner.id)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <h2>Manage Delivery Boys</h2>
          <div className="table-container">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {deliveryBoys.map((boy) => (
                  <tr key={boy.id}>
                    <td>{boy.id}</td>
                    <td>{boy.name}</td>
                    <td>{boy.email}</td>
                    <td>
                      <Button variant="danger" onClick={() => deleteDeliveryBoy(boy.id)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default AdminDashboard;
