import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import "../../asset/css/DeliveryDash.css";

const DeliveryDash = () => {
  const [orders, setOrders] = useState([]); // Store orders as an array
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [notification, setNotification] = useState("");
  const [isOnline, setIsOnline] = useState(localStorage.getItem("deliveryBoyStatus") === "Online");

  const deliveryBoyId = localStorage.getItem("deliveryBoyId");
  const deliveryBoyName = localStorage.getItem("deliveryBoyName") || "Delivery Boy";
  const deliveryBoyPhoto = localStorage.getItem("deliveryBoyPhoto") || "https://via.placeholder.com/150";

  // Fetch Delivery Orders
  const fetchDeliveryStatus = useCallback(async () => {
    try {
      if (!deliveryBoyId) {
        setError("No delivery boy logged in.");
        setLoading(false);
        return;
      }

      const response = await axios.get("https://hungreli.com/api/getdeliveryboydeliverydetails.php", {
        params: { deliveryBoyId },
      });

      console.log("API Response:", response.data); // Debugging

      setOrders(response.data.orders || []); // Store only orders
      setError("");
      setLoading(false);
    } catch (err) {
      setError(err.response?.data?.message || "Error fetching delivery status.");
      setLoading(false);
    }
  }, [deliveryBoyId]);

  useEffect(() => {
    fetchDeliveryStatus();
    const interval = setInterval(fetchDeliveryStatus, 10000);
    return () => clearInterval(interval);
  }, [fetchDeliveryStatus]);

  // Update Order Status
  const updateStatus = async (orderId, newStatus) => {
    try {
      setUpdateLoading(true);
      await axios.post("https://hungreli.com/api/updateDeliveryStatus.php", {
        orderId,
        status: newStatus,
      });
      
      setNotification(`Status updated to "${newStatus}"`);
      fetchDeliveryStatus(); // Refresh orders after update
    } catch (err) {
      setNotification("Error updating status");
    } finally {
      setUpdateLoading(false);
    }
  };

  // Update Location
  const updateLocation = async (latitude, longitude) => {
    try {
      await axios.post(
        "https://hungreli.com/api/getdeliverycurrentlocation.php",
        { deliveryBoyId, latitude, longitude },
        { headers: { "Content-Type": "application/json" } }
      );
    } catch (err) {
      console.error("Error updating location", err);
    }
  };

  // Get Current Location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          updateLocation(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location", error);
        }
      );
    }
  };

  // Toggle Online/Offline Status
  const toggleOnlineStatus = async () => {
    try {
      const newStatus = !isOnline ? "Online" : "Offline";
      setIsOnline(!isOnline);
      localStorage.setItem("deliveryBoyStatus", newStatus); // Save status in localStorage

      await axios.post(
        "https://hungreli.com/api/getupdateDeliveryBoyStatus.php",
        { deliveryBoyId, status: newStatus },
        { headers: { "Content-Type": "application/json" } }
      );

      setNotification(`You are now ${newStatus}`);
      if (newStatus === "Online") {
        getLocation();
      }
    } catch (err) {
      setNotification("Error updating online status");
    }
  };

  return (
    <div className="delivery-dash">
      <header className="header">
        <h1>Delivery Dashboard</h1>
        <p>Manage your deliveries efficiently!</p>
      </header>

      <div className="profile-card">
        <img src={deliveryBoyPhoto} alt="Profile" className="profile-photo" />
        <div className="profile-info">
          <h2>{deliveryBoyName}</h2>
          <p>ID: {deliveryBoyId}</p>
          <button className={isOnline ? "online-btn active" : "online-btn"} onClick={toggleOnlineStatus}>
            {isOnline ? "Go Offline" : "Go Online"}
          </button>
        </div>
      </div>

      <main className="dashboard-content">
        {notification && <div className="notification">{notification}</div>}
        {loading ? (
          <div className="loading">Loading...</div>
        ) : error ? (
          <div className="error-message">{error}</div>
        ) : orders.length > 0 ? ( // Check if orders exist
          orders.map((order) => (
            <div key={order.id} className="delivery-status-card">
              <div className="card-header">
                <h2>Order Details</h2>
                <button className="refresh-button" onClick={fetchDeliveryStatus}>Refresh</button>
              </div>
              <div className="card-content">
                <p><strong>Order ID:</strong> {order.id}</p>
                <p><strong>Status:</strong> {order.status}</p>
                {order.customerName && <p><strong>Customer:</strong> {order.customerName}</p>}
                {order.restaurantName && <p><strong>Restaurant:</strong> {order.restaurantName}</p>}
                {order.address && <p><strong>Address:</strong> {order.address}</p>}
              </div>
              <div className="card-actions">
                {order.status !== "Picked Up" && <button onClick={() => updateStatus(order.id, "Picked Up")} disabled={updateLoading}>Mark as Picked Up</button>}
                {order.status === "Picked Up" && <button onClick={() => updateStatus(order.id, "Delivered")} disabled={updateLoading}>Mark as Delivered</button>}
              </div>
              <button className="navigate-button" onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(order.address)}`, "_blank")}>Navigate</button>
            </div>
          ))
        ) : (
          <div>No delivery data available.</div>
        )}
      </main>

      <footer className="footer">
        <p>Need help? <a href="mailto:support@hungreli.com">Contact Support</a></p>
      </footer>
    </div>
  );
};

export default DeliveryDash;


