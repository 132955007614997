import React, { useState } from "react";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../../asset/css/DeliveryBoyPage.css";


function DeliveryBoyPage() {
  const [form, setForm] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    contact_emergency: "",
    govt_address_proof: "",
    id_proof: "",
    address: "",
    declaration_accepted: false,
  });
  const [isLogin, setIsLogin] = useState(true);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    try {
      if (isLogin) {
        // Login logic
        const response = await axios.post(
          "https://hungreli.com/api/delivery_Boy_login.php",
          {
            email: form.email,
            password: form.password,
          }
        );

        if (response.data.status === "success") {
          localStorage.setItem("deliveryBoyId", response.data.data.email); // Save ID
          localStorage.setItem("deliveryBoyName", response.data.data.name); // Save ID
          setMessage(response.data.message);
          navigate("/DeliveryDash");
        }
      } else {
        // Registration logic
        if (!form.declaration_accepted) {
          setMessage("You must accept the declaration to proceed.");
          return;
        }

        const response = await axios.post(
          "https://hungreli.com/api/delivery_boy_register.php",
          form
        );

        if (response.data.status === "success") {
          setMessage("Registration successful! Please login.");
        
          setIsLogin(true); // Switch to login mode
        }
      }
    } catch (err) {
      setMessage(err.response?.data?.message || "An error occurred!");
    }
  };

  return (
      <div className={`${isLogin === "Delivery Boy Login" ? "card p-4" : "carddeliverypage p-4 mt-5"}`}>
      <h2>{isLogin ? "Delivery Boy Login" : "Delivery Boy Registration"}</h2>
      <form onSubmit={handleSubmit}>
        {!isLogin && (
          <>
            <div className="mb-3">
              <label className="form-label">Name</label>
              <input
                type="varchar"
                className="form-control"
                name="name"
                value={form.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Username</label>
              <input
                type="varchar"
                className="form-control"
                name="username"
                value={form.username}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Contact (Emergency)</label>
              <input
                type="varchar"
                className="form-control"
                name="contact_emergency"
                value={form.contact_emergency}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Address</label>
              <input
                type="varchar"
                className="form-control"
                name="address"
                value={form.address}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Government Address Proof</label>
              <input
                type="varchar"
                className="form-control"
                name="govt_address_proof"
                value={form.govt_address_proof}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label">ID Proof</label>
              <input
                type="varchar"
                className="form-control"
                name="id_proof"
                value={form.id_proof}
                onChange={handleChange}
                required
              />
            </div>
          </>
        )}
        <div className="mb-3">
          <label className="form-label">Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={form.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label className="form-label">Password</label>
          <div className="input-group">
            <input
              type={passwordVisible ? "text" : "password"}
              className="form-control"
              name="password"
              value={form.password}
              onChange={handleChange}
              required
            />
            <span
              className="input-group-text"
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer" }}
            >
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
        </div>
        {!isLogin && (
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              name="declaration_accepted"
              checked={form.declaration_accepted}
              onChange={handleChange}
              required
            />
            <label className="form-check-label">
              I declare that the information provided is true and accurate.
            </label>
          </div>
        )}
        <button type="submit" className="btn btn-primary">
          {isLogin ? "Login" : "Register"}
        </button>
        <div className="mt-3">
          <button
            type="button"
            className="btn btn-link"
            onClick={() => {
              setIsLogin(!isLogin);
              setMessage("");
            }}
          >
            {isLogin
              ? "Don't have an account? Register"
              : "Already have an account? Login"}
          </button>
        </div>
      </form>
      {message && <div className="alert alert-info mt-3">{message}</div>}
    </div>
  );
}

export default DeliveryBoyPage;
