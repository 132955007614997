import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "../../asset/css/RestaurantDash.css";

const RestaurantDash = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [restaurantData, setRestaurantData] = useState(null);
  const [activeTab, setActiveTab] = useState("restaurant"); // "restaurant" or "orders"
  const [orderData, setOrderData] = useState([]); // holds orders fetched from API
  const [statusUpdate, setStatusUpdate] = useState({}); // holds selected statuses for orders
  const navigate = useNavigate();

  // Fetch Restaurant Status
  const fetchRestaurantStatus = async () => {
    const loggedInPartnerId = localStorage.getItem("restaurant_partner_id");
    console.log("Logged In Partner ID:", loggedInPartnerId);

    try {
      const response = await axios.get(
        "https://hungreli.com/api/getresturentstatus.php",
        {
          params: { restaurantPartnerId: loggedInPartnerId },
        }
      );
      console.log("Logged In response:", response);

      if (response.data.status === "success") {
        setRestaurantData(response.data);
      } else {
        setErrorMessage("Failed to fetch restaurant status.");
      }
    } catch (err) {
      setErrorMessage(
        err.response?.data?.message || "Error fetching restaurant status."
      );
    }
  };

  // Fetch Order Data from API
  const fetchOrderData = async () => {
    const loggedInPartnerId = localStorage.getItem("restaurant_partner_id");
    try {
      const response = await axios.get("https://hungreli.com/api/getallorder.php", {
        params: { restaurantPartnerId: loggedInPartnerId },
      });
      // Assuming your API returns a structure like { status: "success", data: [...] }
      if (response.data.status === "success") {
        setOrderData(response.data.data);
      } else {
        console.error("Failed to fetch orders:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  // Update order status and database
  const handleStatusChange = async (orderId, newStatus) => {
    console.log(`Updating order ${orderId} to status ${newStatus}`);
    try {
      // Call your API to update the order status in the database
      const response = await axios.post("https://hungreli.com/api/getallorder.php", {
        orderId,
        status: newStatus,
      });
      if (response.data.status === "success") {
        // After a successful API update, update local state
        setOrderData((prevOrders) =>
          prevOrders.map((order) =>
            order.orderId === orderId ? { ...order, status: newStatus } : order
          )
        );
      } else {
        console.error("Failed to update order status:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating order status:", error);
    }
  };

  useEffect(() => {
    fetchRestaurantStatus();
    fetchOrderData();
  }, []);

  const handleViewProfile = () => {
    navigate(""); // Navigate to the AdminProfile page
    setDropdownVisible(!dropdownVisible);
  };

  const handleRegisterFood = () => {
    navigate("/restaurantFood"); // Navigate to the RestaurantFood registration page
  };

  const handlereturentlist = () => {
    navigate("/restaurantlistpage"); // Navigate to the Restaurant list page
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Clear authentication token
    navigate("/home"); // Redirect to Home
  };

  return (
    <div className="res-dashboard">
      {/* Header Section */}
      <div className="dashboard-header">
        <h1 className="dashboard-heading">Restaurant Dashboard</h1>
        <div className="profile-dropdown">
          <button className="view-profile-btn" onClick={handleViewProfile}>
            View Profile
          </button>
        </div>
        {dropdownVisible && (
          <div className="dropdown-menu">
            <button className="logout-btn" onClick={handleLogout}>
              Logout
            </button>
          </div>
        )}
      </div>

      {/* Tab Navigation */}
      <div className="tabs">
        <button
          className={`tab-button ${activeTab === "restaurant" ? "active" : ""}`}
          onClick={() => setActiveTab("restaurant")}
        >
          Restaurant Info
        </button>
        <button
          className={`tab-button ${activeTab === "orders" ? "active" : ""}`}
          onClick={() => setActiveTab("orders")}
        >
          Food Orders
        </button>
      </div>

      {/* Tab Content */}
      {activeTab === "restaurant" ? (
        <div className="restaurant-info">
          {/* Status Table */}
          <div className="status-table">
            {errorMessage ? (
              <p className="errorMessage">{errorMessage}</p>
            ) : restaurantData ? (
              <table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{restaurantData.restaurantPartnerstatus}</td>
                    <td>{restaurantData.statusrestaurant}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <p>Loading...</p>
            )}
          </div>

          {/* Food Registration and List */}
          <div className="res-container">
            {/* Food Registration Info */}
            <div className="food-registration-box">
              <h2>Registration of Food Items</h2>
              <p>
                Register the food items offered by the restaurant. You can select
                food categories, provide pricing details, discounts, and upload
                multiple images for each food item. This will help in managing the
                menu effectively.
              </p>
            </div>

            {/* Register Food Button */}
            <div className="register-food-box">
              <h3>Register Food by Restaurant</h3>
              <button className="register-food-btn" onClick={handleRegisterFood}>
                Register Food
              </button>
            </div>

            {/* Restaurant List Button */}
            <div className="register-food-box">
              <h3>Restaurant List</h3>
              <button className="register-food-btn" onClick={handlereturentlist}>
                View List
              </button>
            </div>

            {/* About Section */}
            <div className="about-section mt-4">
              <p>
                Our platform is dedicated to connecting customers with the best
                local restaurants and ensuring timely delivery with the help of
                our skilled delivery personnel. We aim to create a seamless dining
                experience from order to delivery.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="food-orders">
          <h2>Food Orders</h2>
          <table>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Address</th>
                <th>Delivery Time</th>
                <th>Tip</th>
                <th>Total</th>
                <th>Payment Method</th>
                <th>Order Date</th>
                <th>Status</th>
                <th>Actions</th>
                <th>Items</th>
              </tr>
            </thead>
            <tbody>
              {orderData.map((order) => (
                <tr key={order.orderId}>
                  <td>{order.orderId}</td>
                  <td>{order.address}</td>
                  <td>{order.deliveryTime}</td>
                  <td>{order.tip}</td>
                  <td>{order.total}</td>
                  <td>{order.paymentMethod}</td>
                  <td>{order.orderDate}</td>
                  <td>{order.status}</td>
                  <td>
                    <select
                      value={statusUpdate[order.orderId] || order.status}
                      onChange={(e) =>
                        setStatusUpdate({
                          ...statusUpdate,
                          [order.orderId]: e.target.value,
                        })
                      }
                    >
                      <option value="Pending">Pending</option>
                      <option value="Preparing">Preparing</option>
                      <option value="Out for Delivery">Out for Delivery</option>
                      <option value="Delivered">Delivered</option>
                    </select>
                    <button
                      onClick={() =>
                        handleStatusChange(
                          order.orderId,
                          statusUpdate[order.orderId] || order.status
                        )
                      }
                    >
                      Update
                    </button>
                  </td>
                  <td>
                    <table>
                      <thead>
                        <tr>
                          <th>Food Name</th>
                          <th>Category</th>
                          <th>Description</th>
                          <th>In Stock</th>
                          <th>Item Price</th>
                          <th>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {order.items.map((item, index) => (
                          <tr key={index}>
                            <td>{item.foodName}</td>
                            <td>{item.category}</td>
                            <td>{item.description}</td>
                            <td>{item.inStock ? "Yes" : "No"}</td>
                            <td>{item.itemPrice}</td>
                            <td>{item.quantity}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default RestaurantDash;
